export default {
  title: {
    id: 'services_bouygues_offers_include_item2_title',
    defaultMessage:
      'Appels illimités depuis la France métropolitaine vers les fixes de 120 destinations',
  },
  europeTitle: {
    id: 'services_bouygues_offers_include_item2_content_europe_title',
    defaultMessage: 'Europe et DOM / TOM :',
  },
  europeBody: {
    id: 'services_bouygues_offers_include_item2_content_europe',
    defaultMessage:
      'Açores (Les), Allemagne, Andorre, Angleterre, Autriche, Baléares (Les), Belgique, Bermudes, Bulgarie, Canaries (Les), Chypre, Corfou, Crète, Croatie, Cyclades (Les), Danemark, Ecosse, Espagne, Estonie, Finlande Gibraltar, Grèce, Grenade, Guadeloupe (et son archipel La Désirade, les Saintes, Marie Galante), Guernesey, Guyane Française, Hongrie, Iles Åland, Iles Caïmans, Ile de Man, Ile de Wight, Ile Rhodes, Iles Féroé, Iles Pitcairn, Irlande, Irlande du Nord, Islande, Italie, Jersey, La Réunion, Liechtenstein, Lituanie, Luxembourg, Macédoine, Madère, Malte, Martinique, Mayotte, Norvège, Pays de Galles, Pays-Bas, Pologne, Portugal, République tchèque, Roumanie, Russie, Saint Marin, Saint Martin, Sardaigne, Sicile, Slovaquie, Slovénie, St-Barthélemy, St Pierre et Miquelon, Suède, Vatican',
  },
  africaTitle: {
    id: 'services_bouygues_offes_include_item2_content_africa_title',
    defaultMessage: 'Afrique :',
  },
  africaBody: {
    id: 'services_bouygues_offers_include_item2_content_africa',
    defaultMessage:
      'Afrique du Sud, Maroc (sauf les numéros commençant par 212526, 212527, 212533, 212534, 212540, 212546, 212547, 212550, 212553 facturés au tarif de 0,81€ TTC/min)',
  },
  asiaOceaniaTitle: {
    id: 'services_bouygues_offers_include_item2_content_asia_oceania_title',
    defaultMessage: 'Asie & Océanie :',
  },
  asiaOceaniaBody: {
    id: 'services_bouygues_offers_include_item2_content_asia_oceania',
    defaultMessage:
      'Australie, Bahamas, Bahreïn, Bangladesh, Brunei, Chine, Corée du Sud, Hong Kong, Îles Christmas, îles Cocos, Inde, Indonésie, Israël, Japon, Jordanie, Macao, Malaisie, Mongolie, Nouvelle-Zélande, Singapour, Taïwan, Tasmanie, Thaïlande, Trinité-et-Tobago',
  },
  americaTitle: {
    id: 'services_bouygues_offers_include_item2_content_america_title',
    defaultMessage: 'Amérique :',
  },
  americaBody: {
    id: 'services_bouygues_offers_include_item2_content_america',
    defaultMessage:
      'Alaska, Argentine, Aruba, Bélize, Brésil, Canada, Chili, Colombie, Costa Rica, États-Unis Guam, Hawaï, Iles de Pâques, Îles Mariannes du nord, Iles vierges américaines, Panama, Paraguay, Pérou, Porto-Rico, Samoa Américaines, Saipan, Tinian, Venezuela',
  },
}
