export default {
  title: {
    id: 'services_bouygues_offers_include_item6_title',
    defaultMessage: '30% de remise sur la réparation express avec WeFix',
  },
  body: {
    id: 'services_bouygues_offers_include_item6_content',
    defaultMessage:
      'Remise valable dans toutes les boutiques Bouygues Telecom sans condition de minimum de réparation ou de montant maximum de remise. Cette remise est valable 2 fois par an.',
  },
}
