import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import { StepIndexes, type StepStatus } from '../NoGrade.constants'

import translations from './getDescription.translations'

/**
 * Retrieve the description from a grade and an offer type.
 * To be used in several places, including product page & checkout funnel.
 */
export const getDescription = (
  i18n: I18n,
  grade: 9 | 10 | 11 | 12 | null,
  offerType: 0 | 7 | null = 0,
): Array<string> => {
  const items: Array<string> = []

  switch (grade) {
    case 9:
      items.push(i18n(translations.premium))
      break
    case 10:
      items.push(i18n(translations.screen_flawless))
      items.push(i18n(translations.body_flawless))
      break
    case 11:
      items.push(i18n(translations.screen_flawless))
      items.push(i18n(translations.body_gently_used))
      break
    case 12:
      items.push(i18n(translations.screen_gently_used))
      items.push(i18n(translations.body_gently_used))
      break
    default:
      break
  }

  if (grade !== 9) {
    if (offerType === 7) {
      items.push(i18n(translations.battery_new))
    } else if (offerType === 0) {
      items.push(i18n(translations.battery_standard))
    }
  }

  return items
}

export const getDescriptionStickyBar = (
  i18n: I18n,
  activeStep: number,
  stepStatuses: Record<number, StepStatus>,
  hasUnavailableScreen: boolean,
  hasUnavailableBody: boolean,
  hasUnavailableBattery: boolean,
  grade: 9 | 10 | 11 | 12 | null,
  offerType: 0 | 7 | null = 0,
): Array<string> => {
  const items: Array<string> = []

  // If screen has been selected, we add the label for screen.
  // If body has been selected, we added the label for body.
  switch (grade) {
    case 9:
      items.push(i18n(translations.premium))
      break
    case 10:
      if (stepStatuses[0] !== 'unknown' || hasUnavailableScreen) {
        items.push(i18n(translations.screen_flawless))
      }
      if (stepStatuses[1] !== 'unknown' || hasUnavailableBody) {
        items.push(i18n(translations.body_flawless))
      }
      break
    case 11:
      if (stepStatuses[0] !== 'unknown' || hasUnavailableScreen) {
        items.push(i18n(translations.screen_flawless))
      }
      if (stepStatuses[1] !== 'unknown' || hasUnavailableBody) {
        items.push(i18n(translations.body_gently_used))
      }
      break
    case 12:
      if (stepStatuses[0] !== 'unknown' || hasUnavailableScreen) {
        items.push(i18n(translations.screen_gently_used))
      }
      if (stepStatuses[1] !== 'unknown' || hasUnavailableBody) {
        items.push(i18n(translations.body_gently_used))
      }
      break
    default:
      break
  }

  // We get the description of the new battery
  // When premium is not selected and a selection has been made in battery step,
  // Or when premium is not selected and battery step is unavailable.
  //      We check that the activeStep is beyond the battery step to make sure we are not getting the label before.
  if (
    grade !== 9 &&
    (stepStatuses[2] !== 'unknown' ||
      (activeStep >= StepIndexes.PREMIUM && hasUnavailableBattery))
  ) {
    if (offerType === 7) {
      items.push(i18n(translations.battery_new))
    } else if (offerType === 0) {
      items.push(i18n(translations.battery_standard))
    }
  }

  return items
}
