export default {
  drawerTitle: {
    id: 'services_bouygues_offers_drawer_title',
    defaultMessage: 'Les offres Bouygues en détail',
  },
  closeButtonLabel: {
    id: 'services_bouygues_offers_drawer_close_button_label',
    defaultMessage: 'Fermer Bouygues Telecom assistance',
  },
  title: {
    id: 'services_bouygues_offers_title',
    defaultMessage: 'Avec Bouygues Telecom, l’avantage c’est les avantages.',
  },
  bboxOffer: {
    id: 'services_bouygues_offers_bbox_offer',
    defaultMessage:
      'Economisez 5€ supplémentaires par mois si vous êtes déjà client BBox',
  },
  bboxOfferTooltip: {
    id: 'services_bouygues_offers_bbox_offer_tooltip',
    defaultMessage:
      'Offre soumise à conditions, remboursement automatique sur facture : 5€/mois. Hors résiliation ou changement d’offre.',
  },
  offerIncludeTitle: {
    id: 'services_bouygues_offers_include_title',
    defaultMessage: 'Inclus dans chaque forfait',
  },
  offerIncludeItem1Title: {
    id: 'services_bouygues_offers_include_item1_title',
    defaultMessage:
      'Appels et SMS illimités depuis et vers France métropolitaine/Europe/DOM/Suisse (5G)',
  },
  offerIncludeItem2Title: {
    id: 'services_bouygues_offers_include_item2_title',
    defaultMessage:
      'Appels illimités depuis la France métropolitaine vers les fixes de 120 destinations',
  },
  offerIncludeItem2ContentEuropeTitle: {
    id: 'services_bouygues_offers_include_item2_content_europe_title',
    defaultMessage: 'Europe et DOM / TOM :',
  },
  offerIncludeItem2ContentEurope: {
    id: 'services_bouygues_offers_include_item2_content_europe',
    defaultMessage:
      'Açores (Les), Allemagne, Andorre, Angleterre, Autriche, Baléares (Les), Belgique, Bermudes, Bulgarie, Canaries (Les), Chypre, Corfou, Crète, Croatie, Cyclades (Les), Danemark, Ecosse, Espagne, Estonie, Finlande Gibraltar, Grèce, Grenade, Guadeloupe (et son archipel La Désirade, les Saintes, Marie Galante), Guernesey, Guyane Française, Hongrie, Iles Åland, Iles Caïmans, Ile de Man, Ile de Wight, Ile Rhodes, Iles Féroé, Iles Pitcairn, Irlande, Irlande du Nord, Islande, Italie, Jersey, La Réunion, Liechtenstein, Lituanie, Luxembourg, Macédoine, Madère, Malte, Martinique, Mayotte, Norvège, Pays de Galles, Pays-Bas, Pologne, Portugal, République tchèque, Roumanie, Russie, Saint Marin, Saint Martin, Sardaigne, Sicile, Slovaquie, Slovénie, St-Barthélemy, St Pierre et Miquelon, Suède, Vatican',
  },
  offerIncludeItem2ContentAfricaTitle: {
    id: 'services_bouygues_offes_include_item2_content_africa_title',
    defaultMessage: 'Afrique :',
  },
  offerIncludeItem2ContentAfrica: {
    id: 'services_bouygues_offers_include_item2_content_africa',
    defaultMessage:
      'Afrique du Sud, Maroc (sauf les numéros commençant par 212526, 212527, 212533, 212534, 212540, 212546, 212547, 212550, 212553 facturés au tarif de 0,81€ TTC/min)',
  },
  offerIncludeItem2ContentAsiaOceaniaTitle: {
    id: 'services_bouygues_offers_include_item2_content_asia_oceania_title',
    defaultMessage: 'Asie & Océanie :',
  },
  offerIncludeItem2ContentAsiaOceania: {
    id: 'services_bouygues_offers_include_item2_content_asia_oceania',
    defaultMessage:
      'Australie, Bahamas, Bahreïn, Bangladesh, Brunei, Chine, Corée du Sud, Hong Kong, Îles Christmas, îles Cocos, Inde, Indonésie, Israël, Japon, Jordanie, Macao, Malaisie, Mongolie, Nouvelle-Zélande, Singapour, Taïwan, Tasmanie, Thaïlande, Trinité-et-Tobago',
  },
  offerIncludeItem2ContentAmericaTitle: {
    id: 'services_bouygues_offers_include_item2_content_america_title',
    defaultMessage: 'Amérique :',
  },
  offerIncludeItem2ContentAmericaOceania: {
    id: 'services_bouygues_offers_include_item2_content_america',
    defaultMessage:
      'Alaska, Argentine, Aruba, Bélize, Brésil, Canada, Chili, Colombie, Costa Rica, États-Unis Guam, Hawaï, Iles de Pâques, Îles Mariannes du nord, Iles vierges américaines, Panama, Paraguay, Pérou, Porto-Rico, Samoa Américaines, Saipan, Tinian, Venezuela',
  },
  offer150GoIncludeItem3Title: {
    id: 'services_bouygues_offers_include_item3_title',
    defaultMessage:
      'Pour le forfait 150G, 30 Go utilisables dans 10 autres destinations internationales',
  },
  offer150GoIncludeItem3Content: {
    id: 'services_bouygues_offers_include_item3_content',
    defaultMessage:
      'Turquie, Tunisie, Etats-Unis, Canada, Chine, Australie, Brésil, Israël, Japon, Thaïlande.',
  },
  offer200GoIncludeItem4Title: {
    id: 'services_bouygues_offers_include_item4_title',
    defaultMessage:
      'Pour le forfait 200G, 35 Go utilisables dans 15 autres destinations internationales',
  },
  offer200GoIncludeItem4Content: {
    id: 'services_bouygues_offers_include_item4_content',
    defaultMessage:
      'Turquie, Tunisie, Etats-Unis, Canada, Chine, Australie, Brésil, Israël, Japon, Thaïlande, Afrique du Sud, Corée du Sud, Egypte, Mexique, Nouvelle-Zélande.',
  },
  offerIncludeItem5Title: {
    id: 'services_bouygues_offers_include_item5_title',
    defaultMessage:
      "Prêt gratuit d'un téléphone équivalent en cas de panne, perte, vol ou casse (même système d'exploitation)",
  },
  offerIncludeItem6Title: {
    id: 'services_bouygues_offers_include_item6_title',
    defaultMessage: '30% de remise sur la réparation express avec WeFix',
  },
  offerIncludeItem6Content: {
    id: 'services_bouygues_offers_include_item6_content',
    defaultMessage:
      'Remise valable dans toutes les boutiques Bouygues Telecom sans condition de minimum de réparation ou de montant maximum de remise. Cette remise est valable 2 fois par an.',
  },
  offerIncludeItem7Title: {
    id: 'services_bouygues_offers_include_item7_title',
    defaultMessage:
      'Une 2ème carte SIM Internet pour utiliser votre forfait sur un second appareil',
  },
  offerIncludeItem7Content: {
    id: 'services_bouygues_offers_include_item7_content',
    defaultMessage: 'Tablette, montre... à vous de choisir.',
  },
  offerIncludeItem8Title: {
    id: 'services_bouygues_offers_include_item8_title',
    defaultMessage: '24 mois de solutions sécurité Norton',
  },
  offerIncludeItem8Content: {
    id: 'services_bouygues_offers_include_item8_content',
    defaultMessage:
      "L'option Solutions Sécurité Smartphone avec Norton vous protège activement contre les menaces d'internet. Vous naviguez en toute sécurité sur votre smartphone !",
  },
  offerIncludeItem8ContentBullet1: {
    id: 'services_bouygues_offers_include_item8_content_bullet1',
    defaultMessage:
      'Sécurité SMS : filtre vos SMS de SPAMS qui pourraient contenir des liens malveillants.',
  },
  offerIncludeItem8ContentBullet2: {
    id: 'services_bouygues_offers_include_item8_content_bullet2',
    defaultMessage:
      'Protection web : détecte les sites web, liens frauduleux et vous protège des risques.',
  },
  offerIncludeItem8ContentBullet3: {
    id: 'services_bouygues_offers_include_item8_content_bullet3',
    defaultMessage:
      'Gestionnaire de MDP : permet de créer, stocker et gérer facilement vos mots de passe et informations de carte de crédit dans un espace sécurisé.',
  },
  offerIncludeItem8ContentBullet4: {
    id: 'services_bouygues_offers_include_item8_content_bullet4',
    defaultMessage:
      'Sécurité WiFi : détecte les réseaux WiFi non sécurisés sur lesquels des cybercriminels peuvent vous espionner pour collecter vos données personnelles ou infecter vos appareils.',
  },
  offerIncludeItem8ContentBullet5: {
    id: 'services_bouygues_offers_include_item8_content_bullet5',
    defaultMessage:
      'VPN(2) : vous rend anonyme lors de vos connexions sur un réseau mobile ou WiFi hors de la maison.',
  },
  offerIncludeItem8ContentBullet6: {
    id: 'services_bouygues_offers_include_item8_content_bullet6',
    defaultMessage:
      'Sécurité de l’appareil : vous alerte lorsque votre système d’exploitation n’est pas à jour et pourrait être vulnérable aux attaques de cybercriminels.',
  },
  offerIncludeItem8ContentBullet7: {
    id: 'services_bouygues_offers_include_item8_content_bullet7',
    defaultMessage:
      'Calendrier sécurisé sur iOS : après avoir cliqué sur un lien malveillant, vous recevez des notifications d’événements intrusives sur votre calendrier Apple.',
  },
  offerIncludeItem8ContentBullet8: {
    id: 'services_bouygues_offers_include_item8_content_bullet8',
    defaultMessage:
      'Sécurité App : vous alerte lorsque vous téléchargez une application jugée à risque.',
  },
  offerIncludeItem9Title: {
    id: 'services_bouygues_offers_include_item9_title',
    defaultMessage: 'Option de contrôle parental gratuite',
  },
  keepYourPhoneNumberTitle: {
    id: 'services_bouygues_offers_keep_your_phone_number_title',
    defaultMessage: 'Comment ça marche',
  },
  keepYourPhoneNumberContent: {
    id: 'services_bouygues_offers_keep_your_phone_number_content',
    defaultMessage:
      'Si vous souhaitez garder votre numéro de téléphone, Bouygues Telecom s’occupe de tout pour changer le contrat avec votre fournisseur actuel.',
  },
}
