export default {
  title: {
    id: 'services_bouygues_offers_include_item3_title',
    defaultMessage:
      'Pour le forfait 150G, 30 Go utilisables dans 10 autres destinations internationales',
  },
  body: {
    id: 'services_bouygues_offers_include_item3_content',
    defaultMessage:
      'Turquie, Tunisie, Etats-Unis, Canada, Chine, Australie, Brésil, Israël, Japon, Thaïlande.',
  },
}
