export const StepIndexes = {
  SCREEN: 0,
  SHELL: 1,
  BATTERY: 2,
  PREMIUM: 3,
}

export type StepStatus = 'unknown' | 'accepted' | 'declined'

export const DEFAULT_NO_GRADE_ACTIVE_STEP = StepIndexes.SCREEN
export const DEFAULT_NO_GRADE_STATUSES: Record<number, StepStatus> = {
  [StepIndexes.SCREEN]: 'unknown',
  [StepIndexes.SHELL]: 'unknown',
  [StepIndexes.BATTERY]: 'unknown',
  [StepIndexes.PREMIUM]: 'unknown',
}
export const NO_GRADE_BRAND_INJECTION_KEY = Symbol('BRAND_INJECTION_KEY')

/** The same as tw`bg-float-default-low-disabled` but without the alpha channel */
export const BG_WITH_OPACITY = 'hsl(0deg 0% 95.29%)'
