<template>
  <RevAccordionItem>
    <template #title>
      {{ i18n(translations.title) }}
    </template>

    <template #body>
      {{ i18n(translations.body) }}
      <ul class="mt-28 list-disc pl-14">
        <li>{{ i18n(translations.bullet1) }}</li>
        <li>{{ i18n(translations.bullet2) }}</li>
        <li>{{ i18n(translations.bullet3) }}</li>
        <li>{{ i18n(translations.bullet4) }}</li>
        <li>{{ i18n(translations.bullet5) }}</li>
        <li>{{ i18n(translations.bullet6) }}</li>
        <li>{{ i18n(translations.bullet7) }}</li>
        <li>{{ i18n(translations.bullet8) }}</li>
      </ul>
    </template>

    <template #prefix><IconCheckInCircle /></template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './Norton24Months.translations'

const i18n = useI18n()
</script>
