export default {
  title: {
    id: 'services_bouygues_offers_include_item7_title',
    defaultMessage:
      'Une 2ème carte SIM Internet pour utiliser votre forfait sur un second appareil',
  },
  body: {
    id: 'services_bouygues_offers_include_item7_content',
    defaultMessage: 'Tablette, montre... à vous de choisir.',
  },
}
