<template>
  <RevAccordionItem>
    <template #title> {{ i18n(translations.title) }}</template>

    <template #prefix>
      <IconCheckInCircle />
    </template>

    <template #body>
      <div class="space-y-24">
        <div>
          <h4 class="body-2-bold">{{ i18n(translations.europeTitle) }}</h4>
          {{ i18n(translations.europeBody) }}
        </div>

        <div>
          <h4 class="body-2-bold">{{ i18n(translations.domTitle) }}</h4>
          {{ i18n(translations.domBody) }}
        </div>
      </div>
    </template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './Plan90goFrance25goEurope.translations'

const i18n = useI18n()
</script>
