export default {
  labelWith5g: {
    id: 'services_bouygues_offers_include_item1_title',
    defaultMessage:
      'Appels et SMS illimités depuis et vers France métropolitaine/Europe/DOM/Suisse (5G)',
  },
  labelWithout5g: {
    id: 'services_bouygues_offers_include_item1_title_no_5g',
    defaultMessage:
      'Appels et SMS illimités depuis et vers France métropolitaine/Europe/DOM/Suisse',
  },
}
