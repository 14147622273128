export default {
  title: {
    id: 'services_bouygues_offers_include_item8_title',
    defaultMessage: '24 mois de solutions sécurité Norton',
  },
  body: {
    id: 'services_bouygues_offers_include_item8_content',
    defaultMessage:
      "L'option Solutions Sécurité Smartphone avec Norton vous protège activement contre les menaces d'internet. Vous naviguez en toute sécurité sur votre smartphone !",
  },
  bullet1: {
    id: 'services_bouygues_offers_include_item8_content_bullet1',
    defaultMessage:
      'Sécurité SMS : filtre vos SMS de SPAMS qui pourraient contenir des liens malveillants.',
  },
  bullet2: {
    id: 'services_bouygues_offers_include_item8_content_bullet2',
    defaultMessage:
      'Protection web : détecte les sites web, liens frauduleux et vous protège des risques.',
  },
  bullet3: {
    id: 'services_bouygues_offers_include_item8_content_bullet3',
    defaultMessage:
      'Gestionnaire de MDP : permet de créer, stocker et gérer facilement vos mots de passe et informations de carte de crédit dans un espace sécurisé.',
  },
  bullet4: {
    id: 'services_bouygues_offers_include_item8_content_bullet4',
    defaultMessage:
      'Sécurité WiFi : détecte les réseaux WiFi non sécurisés sur lesquels des cybercriminels peuvent vous espionner pour collecter vos données personnelles ou infecter vos appareils.',
  },
  bullet5: {
    id: 'services_bouygues_offers_include_item8_content_bullet5',
    defaultMessage:
      'VPN(2) : vous rend anonyme lors de vos connexions sur un réseau mobile ou WiFi hors de la maison.',
  },
  bullet6: {
    id: 'services_bouygues_offers_include_item8_content_bullet6',
    defaultMessage:
      'Sécurité de l’appareil : vous alerte lorsque votre système d’exploitation n’est pas à jour et pourrait être vulnérable aux attaques de cybercriminels.',
  },
  bullet7: {
    id: 'services_bouygues_offers_include_item8_content_bullet7',
    defaultMessage:
      'Calendrier sécurisé sur iOS : après avoir cliqué sur un lien malveillant, vous recevez des notifications d’événements intrusives sur votre calendrier Apple.',
  },
  bullet8: {
    id: 'services_bouygues_offers_include_item8_content_bullet8',
    defaultMessage:
      'Sécurité App : vous alerte lorsque vous téléchargez une application jugée à risque.',
  },
}
