export default {
  premium: {
    id: 'product_page_no_grade_labels_premium',
    defaultMessage: 'Premium',
  },
  screen_flawless: {
    id: 'product_page_no_grade_labels_screen_flawless',
    defaultMessage: 'Flawless screen',
  },
  screen_gently_used: {
    id: 'product_page_no_grade_labels_screen_gently_used',
    defaultMessage: 'Gently used screen',
  },
  body_flawless: {
    id: 'product_page_no_grade_labels_body_flawless',
    defaultMessage: 'Almost no signs of use shell',
  },
  body_gently_used: {
    id: 'product_page_no_grade_labels_body_gently_used',
    defaultMessage: 'Gently used shell',
  },
  battery_standard: {
    id: 'product_page_no_grade_labels_battery_standard',
    defaultMessage: 'Standard battery',
  },
  battery_new: {
    id: 'product_page_no_grade_labels_battery_new',
    defaultMessage: 'New battery',
  },
} as const
