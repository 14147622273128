export default {
  priceWhileKeepingTheSamePhoneNumber: {
    id: 'services_bouygues_offers_price_while_keeping_the_same_phone_number',
    defaultMessage: 'En conservant mon numéro',
  },
  priceWhileChangingPhoneNumber: {
    id: 'services_bouygues_offers_price_while_changing_phone_number',
    defaultMessage: 'En prenant un nouveau numéro',
  },

  onePeriodPrice: {
    id: 'services_bouygues_offers_one_period_price',
    defaultMessage: '{price}/month',
  },
  secondPeriodPrice: {
    id: 'services_bouygues_offers_second_period_price_in_months',
    defaultMessage: 'for {duration} then {secondPeriodPrice}/month',
  },
  enrollmentPeriod: {
    id: 'services_bouygues_offers_enrollment_period',
    defaultMessage: '{duration} engagement',
  },
  discountTag: {
    id: 'services_bouygues_offers_discount_tag',
    defaultMessage: '{price} de remise sur votre téléphone',
  },
}
