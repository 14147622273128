<template>
  <RevListItemStatic class="pl-24">
    <template #label>
      {{ i18n(translations.label) }}
    </template>

    <template #prefix><IconCheckInCircle /></template>
  </RevListItemStatic>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './LoanMobilephone.translations'

const i18n = useI18n()
</script>
