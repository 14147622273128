<template>
  <RevInfoBlock
    v-if="subsidy && title"
    :icon="IconPriceTag"
    :title
    variant="success"
  >
    <RevLink class="caption" @click="isOpen = !isOpen">
      <p v-if="isOpen">
        {{ i18n(translations.subsidyButtonContentOpened) }}
      </p>
      <p v-else>{{ i18n(translations.subsidyButtonContentClosed) }}</p>
    </RevLink>

    <Transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      leave-active-class="opacity-0 transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
    >
      <p
        v-if="isOpen"
        class="text-static-default-mid caption whitespace-pre-line"
      >
        {{ content }}
      </p>
    </Transition>
  </RevInfoBlock>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconPriceTag } from '@ds/icons/IconPriceTag'

import getbenefitsFromMobilePlanOffers from '~/scopes/product/utils/getBenefitsFromMobilePlanOffers'
import { getHighestSubsidy } from '~/scopes/product/utils/getHighestSubsidy'

import translations from './SubsidyInfoBlock.translations'

const isOpen = ref(false)
const i18n = useI18n()

const props = defineProps<{
  offers:
    | MobilePlanOffer[]
    | GetProductResponse['includedServiceOffers']['mobilePlans']
}>()

const subsidy = computed(() => getHighestSubsidy(props.offers))

const benefits = computed(() => getbenefitsFromMobilePlanOffers(props.offers))

const title = computed(() => {
  if (
    benefits.value.includes('FLASH_OFFER_180_EURO_SUBSIDY_INSTEAD_OF_150_EURO')
  ) {
    return i18n(translations.title180EurosSubsidyFlashOffer)
  }

  if (benefits.value.includes('FLASH_OFFER_130_GO_INSTEAD_OF_90_GO')) {
    return i18n(translations.title130GoFlashOffer)
  }

  if (subsidy.value) {
    return i18n(translations.subsidyTitle, {
      subsidy: i18n.price(subsidy.value),
    })
  }

  return null
})

const content = computed(() => {
  if (
    benefits.value.includes('FLASH_OFFER_180_EURO_SUBSIDY_INSTEAD_OF_150_EURO')
  ) {
    return i18n(translations.content180EurosSubsidyFlashOffer)
  }

  if (benefits.value.includes('FLASH_OFFER_130_GO_INSTEAD_OF_90_GO')) {
    return i18n(translations.content130GoFlashOffer)
  }

  if (subsidy.value) {
    return i18n(translations.subsidyContent, {
      subsidy: i18n.price(subsidy.value),
    })
  }

  return null
})
</script>
