<template>
  <RevAccordionItem>
    <template #title> {{ i18n(translations.title) }}</template>

    <template #prefix><IconCheckInCircle /></template>

    <template #body>
      <div class="space-y-24">
        <div class="space-y-24">
          <h4 class="body-2-bold">{{ i18n(translations.europeTitle) }}</h4>
          {{ i18n(translations.europeBody) }}
        </div>

        <div class="space-y-24">
          <h4 class="body-2-bold">{{ i18n(translations.africaTitle) }}</h4>
          {{ i18n(translations.africaBody) }}
        </div>

        <div class="space-y-24">
          <h4 class="body-2-bold">{{ i18n(translations.asiaOceaniaTitle) }}</h4>
          {{ i18n(translations.asiaOceaniaBody) }}
        </div>

        <div class="space-y-24">
          <h4 class="body-2-bold">{{ i18n(translations.americaTitle) }}</h4>
          {{ i18n(translations.americaBody) }}
        </div>
      </div>
    </template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './CallsAndSmsUnlimitedTowardsEurope120Contries.translations'

const i18n = useI18n()
</script>
