export const MODAL_NAMES = {
  ADD_TO_CART: 'ADD_TO_CART',
  MACBOOK_PROCESSOR: 'MACBOOK_PROCESSOR',
  BUNDLE: 'BUNDLE',
  CARRIERS_COMPATIBILITY: 'CARRIERS_COMPATIBILITY',
  RENEWED_IMPACT: 'RENEWED_IMPACT',
  EXCLUDED_ACCESSORIES: 'EXCLUDED_ACCESSORIES',
  FAQ: 'FAQ',
  GRADES: 'GRADES_DETAILS',
  MEDIA_VIEWER: 'MEDIA_VIEWER',
  NEW_BATTERY: 'NEW_BATTERY',
  NEW_BATTERY_NO_GRADE: 'NEW_BATTERY_NO_GRADE',
  PERKS: 'PERKS',
  PREPAID_PLAN: 'PREPAID_PLAN',
  SERVICE_FEE: 'SERVICE_FEE',
  SIM: 'SIM',
  TECH_SPECS: 'TECH_SPECS',
  REASSURANCE: 'REASSURANCE',
  TRADE_IN: 'TRADE_IN',
  VERIFIED_REFURBISHED: 'VERIFIED_REFURBISHED',
  BOUYGUES: 'BOUYGUES_OFFERS',
  VISIBLE_BY_VERIZON_BENEFITS: 'VISIBLE_BY_VERIZON_BENEFITS',
  CONDITION_COMPARISON: 'CONDITION_COMPARISON',
  SELLER_DETAILS: 'SELLER_DETAILS',
} as const

export const ProductCategory = {
  SMARTPHONE: '2',
  MACBOOK_INTEL: '110',
  MACBOOK_M: '528',
  AIRPODS: '217',
}

export const VALID_GRADES = [9, 10, 11, 12]

export const PICKERS_LOGIC = {
  CLOSEST_CONFIGURATION: 1,
  CHEAPEST_CONFIGURATION: 2,
  CHEAPEST_CONFIGURATION_GRADE_ONLY: 3,
}
