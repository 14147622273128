import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

export default function getbenefitsFromMobilePlanOffers(
  offers:
    | MobilePlanOffer[]
    | GetProductResponse['includedServiceOffers']['mobilePlans'],
) {
  return offers.flatMap((offer) => offer.benefits)
}
