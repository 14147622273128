<template>
  <RevAccordionItem>
    <template #title>
      {{ i18n(translations.title) }}
    </template>

    <template #body>
      {{ i18n(translations.body) }}
    </template>

    <template #prefix><IconCheckInCircle /></template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './SecondSim.translations'

const i18n = useI18n()
</script>
