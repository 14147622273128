export default {
  subsidyTitle: {
    id: 'services_bouygues_offers_subsidy_title',
    defaultMessage:
      'Ça commence bien, avec jusqu’à {subsidy} de remise immédiate en souscrivant à un forfait.',
  },
  subsidyButtonContentOpened: {
    id: 'services_bouygues_offers_subsidy_button_content_opened',
    defaultMessage: 'Masquer les conditions',
  },
  subsidyButtonContentClosed: {
    id: 'services_bouygues_offers_subsidy_button_content_closed',
    defaultMessage: 'Consulter les conditions',
  },
  subsidyContent: {
    id: 'services_bouygues_offers_subsidy_content',
    defaultMessage:
      "Si vous avez déjà un forfait Bouygues Telecom, vous bénéficiez de la remise uniquement avec la création d’un nouveau numéro de téléphone. La subvention est soumise à la souscription d'un forfait Bouygues Telecom. En cas de rétractation du forfait, elle devra être restituée.",
  },
  title130GoFlashOffer: {
    id: 'services_bouygues_offers_title_least_expensive_flash_offer',
    defaultMessage:
      "Offre flash : 130 Go au lieu de 90 Go, c'est un bon départ.",
  },
  title180EurosSubsidyFlashOffer: {
    id: 'services_bouygues_offers_title_most_expensive_flash_offer',
    defaultMessage:
      "Offre flash : jusqu'à 180€ de remise immédiate au lieu de 150€, c'est un bon départ.",
  },
  content130GoFlashOffer: {
    id: 'services_bouygues_offers_content_least_expensive_flash_offer',
    defaultMessage:
      "Offre flash jusqu'au 30/04/2025 inclus. Forfait à 9,99€/mois : bénéficiez de 20€ de remise immédiate sur votre téléphone pour 130 Go de données (au lieu de 90 Go). Si vous avez déjà un forfait Bouygues Telecom, vous bénéficiez de la remise uniquement avec la création d'un nouveau numéro de téléphone. La remise est soumise à la souscription d’un forfait Bouygues Telecom. En cas de rétractation, vous êtes redevable auprès de Bouygues Telecom du montant de la réduction dont vous avez bénéficié.",
  },
  content180EurosSubsidyFlashOffer: {
    id: 'services_bouygues_offers_content_most_expensive_flash_offer',
    defaultMessage:
      "Offre flash jusqu'au 30/04/2025 inclus. Forfait à 9,99€/mois : bénéficiez de 20€ de remise immédiate sur votre téléphone pour 130 Go de données (au lieu de 90 Go). Forfait à 19,99€/mois : bénéficiez de 180€ de remise immédiate sur votre téléphone pour 300 Go de données. Si vous avez déjà un forfait Bouygues Telecom, vous bénéficiez de la remise uniquement avec la création d’un nouveau numéro de téléphone. La remise est soumise à la souscription d'un forfait Bouygues Telecom. En cas de rétractation, vous êtes redevable auprès de Bouygues Telecom du montant de la réduction dont vous avez bénéficié.",
  },
}
