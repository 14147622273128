export default {
  title: {
    id: 'services_bouygues_offers_include_35_go_europe_title',
    defaultMessage:
      '300Go en France métropolitaine dont 25Go utilisables en Europe/DOM. Au-delà de l’enveloppe, MMS (hors France métropolitaine) et internet facturés aux tarifs en vigueur',
  },
  europeTitle: {
    id: 'services_bouygues_offers_include_35_go_europe_content_europe_title',
    defaultMessage: 'Europe:',
  },
  europeBody: {
    id: 'services_bouygues_offers_include_35_go_europe_content_europe',
    defaultMessage:
      'Acores (Les), Åland (Iles), Allemagne, Andorre, Angleterre, Autriche, Baléares (Les), Belgique, Bulgarie, Canaries (Les), Chypre, Corfou, Crète, Croatie, Cyclades (Les), Danemark, Ecosse, Espagne, Estonie, Féroé (Iles), Finlande, Gibraltar, Grèce, Guernesey, Hongrie, Irlande, Irlande du Nord, Islande, Italie, Jersey, Lettonie, Liechtenstein, Lituanie, Luxembourg, Madère, Malte, Man (Ile de), Norvège, Pays-Bas, Pays de Galles, Pologne, Portugal, République Tchèque, Rhodes (île), Roumanie, Saint-Marin, Sardaigne, Sicile, Slovaquie, Slovénie, Suède, Vatican, Wight (île de).',
  },
  domTitle: {
    id: 'services_bouygues_offes_include_35_go_europe_content_dom_title',
    defaultMessage: 'DOM :',
  },
  domBody: {
    id: 'services_bouygues_offers_include_35_go_europe_content_dom',
    defaultMessage:
      'Guadeloupe (et son archipel La Désirade, les Saintes, Marie Galante), Guyane française, La Réunion, Martinique, Mayotte + les COM suivants : Saint-Barthélemy, Saint-Martin, Saint Pierre-et-Miquelon.',
  },
}
