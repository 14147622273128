export default {
  title: {
    id: 'services_bouygues_offers_include_item4_title',
    defaultMessage:
      'Pour le forfait 200G, 35 Go utilisables dans 15 autres destinations internationales',
  },
  body: {
    id: 'services_bouygues_offers_include_item4_content',
    defaultMessage:
      'Turquie, Tunisie, Etats-Unis, Canada, Chine, Australie, Brésil, Israël, Japon, Thaïlande, Afrique du Sud, Corée du Sud, Egypte, Mexique, Nouvelle-Zélande.',
  },
}
