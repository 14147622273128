export default {
  title: {
    id: 'services_bouygues_offers_include_network_quality_title',
    defaultMessage: 'Qualité du réseau Bouygues Telecom',
  },
  body: {
    id: 'services_bouygues_offers_include_network_quality_body',
    defaultMessage: 'Le 2ème meilleur réseau mobile en France.',
  },
  note: {
    id: 'services_bouygues_offers_include_network_quality_note',
    defaultMessage:
      '* Classement réalisé par Bouygues Telecom sur la base de l’enquête annuelle 2022 de l’ARCEP sur la qualité des services mobiles métropolitains (2G, 3G, 4G et 5G).',
  },
}
